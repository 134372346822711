import orfeliaHeaderImage from "../../images/orfelia-header-image.png";
import messsyLogo from "../../images/messsy-logo.svg";

<h1>
  <img
    src={orfeliaHeaderImage}
    alt="Stylized header image with the text 'Envisioning the future cannot be left to those in power. The future is not yet queer.' overlaid on a photo of a queer dance floor filled with colorful costumerd attendees"
  />
</h1>

<div className="container">

# A Night at The Orfelia

The Orfelia is a interactive art and performance event series set in 2054 at the queer nightclub of your utopian dreams. Hosted by the Queer Futures Collective, the Orfelia is our estatic vision of a world that lies just within our grasp. Two dozen artists, designers, and technologists (and a team of the most strapping volunteers) from across the Midwest have assembled to make artwork and experiences to help bring this world into the present–and hopefully our future as well.

Grab an embroidered hanky to flag kinks not-yet-possible, lounge in the glow green hues of the algae bioreactor and fondle a genetically engineered slug designed for DIY hormone treatment. The stage and dancefloors beckon: between pumping sets from DJs, catch a performance of queer transmasculine erotics and athleticism with the Transfag Olympics, or maybe cool down in the dirt at The Pit. Sip drinks made with food waste while you generate your gender for tomorrow.

Find us on [Instagram](https://instagram.com/theorfelia) to keep on top of what we're working on.

Doors @ Summer, 2054. Dress your cyber-best.

## Previous Events:

<a href="/orfelia/messsy">
  <img src={messsyLogo} alt="Messsy" />
</a>
</div>
