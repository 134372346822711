import './Logo.css';

export default function Logo() {
  return (
    <svg className="logo" x="0px" y="0px" viewBox="95 0 1220 428" alt="Encoder Rat Decoder Rat">
      <path className="logo-green-line" d="M173.1,156.5v138.9V156.5z"/>
      <circle className="logo-dot" cx="173.1" cy="156.5" r="7"/>
      <g className="logo-rat-e">
        <path d="M239.3,149.4c-4.1,29.4-94.4,33.1-94.4,33.1l14.7-45.1c8.7-26.7,34.2-44.6,62.2-43c9.8,0.6,17.2,3.4,17.5,10.7c0.8,22.8-59.3,35.3-59.3,35.3"/>
        <path className="logo-whisker" d="M179.3,216.6l-16.4-17.5L179.3,216.6z"/>
        <path className="logo-whisker" d="M148.9,199.1l0,24L148.9,199.1z"/>
        <path className="logo-whisker" d="M179.3,216.6l-16.4-17.5L179.3,216.6z"/>
        <path className="logo-whisker" d="M148.9,199.1l0,24L148.9,199.1z"/>
        <path className="logo-whisker" d="M110.8,152.2l17.5,16.4L110.8,152.2z"/>
        <path className="logo-whisker" d="M128.3,182.6l-24,0L128.3,182.6z"/>
        <path className="logo-whisker" d="M110.8,152.2l17.5,16.4L110.8,152.2z"/>
        <path className="logo-whisker" d="M128.3,182.6l-24,0L128.3,182.6z"/>
      </g>
      <path className="logo-e-r" d="M762.3,175.7l22.9-70.3c0,0,58.6-16.4,59.2,2.9c0.7,19.8-51.6,30.8-51.6,30.8s52.9-12.6,52.9,20.6c0,30.2,30.2-1.7,61.3-1.7c12.7,0,21.3,3.4,21.3,8"/>
      <g className="logo-rat-d">
        <path d="M234.5,286.4c-21.7,25.4-94.4,33.1-94.4,33.1l14.7-45.1c8.7-26.7,34.1-43,62.2-43C241.8,231.5,261.2,255.3,234.5,286.4z"/>
        <path className="logo-whisker" d="M176.3,351.4l-16.4-17.5L176.3,351.4z"/>
        <path className="logo-whisker" d="M145.9,333.8l0,24L145.9,333.8z"/>
        <path className="logo-whisker" d="M176.3,351.4l-16.4-17.5L176.3,351.4z"/>
        <path className="logo-whisker" d="M145.9,333.8l0,24L145.9,333.8z"/>
        <path className="logo-whisker" d="M107.8,287l17.5,16.4L107.8,287z"/>
        <path className="logo-whisker" d="M125.3,317.4l-24,0L125.3,317.4z"/>
        <path className="logo-whisker" d="M107.8,287l17.5,16.4L107.8,287z"/>
        <path className="logo-whisker" d="M125.3,317.4l-24,0L125.3,317.4z"/>
      </g>
      <g className="logo-ncoder">
        <path d="M444.3,155.5c-11.3,11.3-27.3,22-57.9,21.8c-19,0-27-15.9-27-24.1c0-38.6,42.6-54.2,62.9-54.2"/>
        <path d="M247.9,178.4l26.3-80.7c0,0,50.2-1.6,50.2,80.7"/>
        <line x1="324.5" y1="178.4" x2="350.8" y2="97.7"/>
        <path d="M750.8,146.8c-3.5,25.6-82.2,28.9-82.2,28.9l12.8-39.2c7.6-23.2,29.7-38.8,54.1-37.4c8.6,0.5,15,3,15.2,9.3c0.7,19.8-51.6,30.8-51.6,30.8"/>
        <path d="M652.1,146.7c-18.9,22.1-82.2,28.9-82.2,28.9l12.8-39.2c7.6-23.2,29.7-37.4,54.1-37.4C658.5,98.9,675.3,119.6,652.1,146.7z"/>
        <path d="M557.4,125.8c0,26.6-36.3,51.7-66.8,51.4c-19,0-27-15.9-27-24.1c0-38.6,42.6-54.2,62.9-54.2C551.4,98.9,557.4,117,557.4,125.8z"/>
      </g>
      <g className="logo-ecoder">
        <path d="M439.1,287.7c-11.3,11.3-27.3,22-57.9,21.8c-19,0-27-15.9-27-24.1c0-38.6,42.6-54.2,62.9-54.2"/>
        <path d="M745.5,279c-3.5,25.6-82.2,28.9-82.2,28.9l12.8-39.2c7.6-23.2,29.7-38.8,54.1-37.4c8.6,0.5,15,3,15.2,9.3c0.7,19.8-51.6,30.8-51.6,30.8"/>
        <path d="M335,279.8c-3.5,25.6-82.2,28.9-82.2,28.9l12.8-39.2c7.6-23.2,29.7-38.8,54.1-37.4c8.6,0.5,15,3,15.2,9.3c0.7,19.8-51.6,30.8-51.6,30.8"/>
        <path d="M646.9,279c-18.9,22.1-82.2,28.9-82.2,28.9l12.8-39.2c7.6-23.2,29.7-37.4,54.1-37.4C653.2,231.1,670.1,251.9,646.9,279z"/>
        <path d="M552.1,258c0,26.6-36.3,51.7-66.8,51.4c-19,0-27-15.9-27-24.1c0-38.6,42.6-54.2,62.9-54.2C546.1,231.1,552.1,249.2,552.1,258z"/>
      </g>
      <path className="logo-d-r" d="M757,307.9l22.9-70.3c0,0,58.6-16.4,59.2,2.9c0.7,19.8-51.6,30.8-51.6,30.8s52.9-12.6,52.9,20.6c0,30.2,30.2-1.7,61.3-1.7c12.7,0,21.3,3.4,21.3,8"/>
      <g className="logo-top-rat">
        <path d="M995.4,180.3l26.3-80.7c0,0,67.3-18.8,68,3.3c0.8,22.8-59.3,35.3-59.3,35.3s60.7-14.5,60.7,23.6c0,13.8-6.7,19.1-6.7,19.1"/>
        <path d="M1116.3,125.6c1.9-13.9,28.4-33.1,50.6-33.1c26.7,0,36.3,23,29,45.1v0c-8.7,26.7-34.2,44.6-62.2,43c-9.8-0.6-17.5-3.4-17.5-10.7c0-28.7,40.6-46.9,58.8-30.8"/>
        <path d="M1190.9,149.1c-8.9,13.5,7.9,28.3,11.1,31.5"/>
        <g>
          <line x1="1236.4" y1="182.4" x2="1262.8" y2="101.6"/>
        </g>
        <line x1="1221.7" y1="99.1" x2="1305.3" y2="99.2"/>
      </g>
      <g className="logo-bottom-rat">
        <path d="M989.9,320.1l26.3-80.7c0,0,67.3-18.8,68,3.3c0.8,22.8-59.3,35.3-59.3,35.3s60.7-14.5,60.7,23.6c0,13.8-6.7,19.1-6.7,19.1"/>
        <path d="M1110.8,265.4c1.9-13.9,28.4-33.1,50.6-33.1c26.7,0,36.3,23,29,45.1v0c-8.7,26.7-34.2,44.6-62.2,43c-9.8-0.6-17.5-3.4-17.5-10.7c0-28.7,40.6-46.9,58.8-30.8"/>
        <path d="M1185.4,288.9c-8.9,13.5,7.9,28.3,11.1,31.5"/>
        <g>
          <line x1="1230.9" y1="322.1" x2="1257.3" y2="241.4"/>
        </g>
        <line x1="1216.2" y1="238.9" x2="1299.8" y2="239"/>
      </g>
    </svg>
    
  )
}

